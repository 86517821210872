import { KeyValueObj } from '@app/types/global.type';

export const invoiceType = [
  { key: 'B2B', name: 'Business to Business' },
  { key: 'B2C', name: 'Business to Consumer' },
  { key: 'B2G', name: 'Business to Government' },
  { key: 'EXPORT', name: 'Export' },
  { key: 'IMPORT', name: 'Import' },
  { key: 'CONSOLDIATED', name: 'Consolidated' },
];

export const documentType = [
  { key: 'Invoice', name: 'Invoice' },
  { key: 'Debit Note', name: 'Debit Note' },
  { key: 'Credit Note', name: 'Credit Note' },
  { key: 'Refund Note', name: 'Refund Note' },
];

export const InvoiceTypes = {
  B2B: 'Business to Business',
  B2C: 'Business to Consumer',
  B2G: 'Business to Government',
  EXPORT: 'Export',
  IMPORT: 'Import',
  CONSOLDIATED: 'Consolidated',
};

export const InvoiceCategories = [
  {
    label: 'Individual',
    value: 'INDIVIDUAL',
    type: 'sales',
  },
  {
    label: 'Consolidated',
    value: 'CONSOLIDATED',
    type: 'sales',
  },
  {
    label: 'Self-billed Individual',
    value: 'SELF_BILLED_INDIVIDUAL',
    type: 'purchase',
  },
  {
    label: 'Self-billed Consolidated',
    value: 'SELF_BILLED_CONSOLIDATED',
    type: 'purchase',
  },
];

export const InvoiceStatus: KeyValueObj = {
  DRAFT: 'Draft',
  SUBMITTED: 'Submitted',
  NOTSUBMITTED: 'Not Submitted',
  VALID: 'Valid',
  CANCELLED: 'Cancelled',
  DELETED: 'Deleted',
  ERRORED: 'Errored',
};

export const ReceivedInvoiceStatus: KeyValueObj = {
  VALID: 'Valid',
  CANCELLED: 'Cancelled',
};

export type InvoiceStatus = keyof typeof InvoiceStatus;

export const InvoiceStatusList = Object.keys(InvoiceStatus).map((e) => {
  return { label: InvoiceStatus[e], value: e };
});

export type ReceivedInvoiceStatus = keyof typeof ReceivedInvoiceStatus;

export const ReceivedInvoiceStatusList = Object.keys(ReceivedInvoiceStatus).map((e) => {
  return { label: ReceivedInvoiceStatus[e], value: e };
});

interface DocumentTypeMap {
  [key: string]: string;
}
export const DocumentTypes: DocumentTypeMap = {
  Invoice: 'Invoice',
  DEBIT: 'Debit Note',
  CREDIT: 'Credit Note',
  REFUND: 'Refund Note',
  SELF_BILLED_INVOICE: 'Self-billed Invoice',
  SELF_BILLED_CREDIT_NOTE: 'Self-billed Credit Note',
  SELF_BILLED_DEBIT_NOTE: 'Self-billed Debit Note',
  SELF_BILLED_REFUND_NOTE: 'Self-billed Refund Note',
};

export const DocumentTypeList = [
  {
    label: 'Invoice',
    value: 'Invoice',
    type: 'sales',
  },
  {
    label: 'Debit Note',
    value: 'Debit Note',
    type: 'sales',
  },
  {
    label: 'Credit Note',
    value: 'Credit Note',
    type: 'sales',
  },
  {
    label: 'Refund Note',
    value: 'Refund Note',
    type: 'sales',
  },
  {
    label: 'Self-billed Invoice',
    value: 'Self-billed Invoice',
    type: 'purchase',
  },
  {
    label: 'Self-billed Credit Note',
    value: 'Self-billed Credit Note',
    type: 'purchase',
  },
  {
    label: 'Self-billed Debit Note',
    value: 'Self-billed Debit Note',
    type: 'purchase',
  },
  {
    label: 'Self-billed Refund Note',
    value: 'Self-billed Refund Note',
    type: 'purchase',
  },
];

export const docCurrency = [
  {
    key: 'MYR',
    name: 'Malaysian Ringgit',
  },
  {
    key: 'USD',
    name: 'US Dollar',
  },
  {
    key: 'AED',
    name: 'UAE Dirham',
  },
  {
    key: 'AFN',
    name: 'Afghani',
  },
  {
    key: 'ALL',
    name: 'Lek',
  },
  {
    key: 'AMD',
    name: 'Armenian Dram',
  },
  {
    key: 'ANG',
    name: 'Netherlands Antillean Guilder',
  },
  {
    key: 'AOA',
    name: 'Kwanza',
  },
  {
    key: 'ARS',
    name: 'Argentine Peso',
  },
  {
    key: 'AUD',
    name: 'Australian Dollar',
  },
  {
    key: 'AWG',
    name: 'Aruban Florin',
  },
  {
    key: 'AZN',
    name: 'Azerbaijan Manat',
  },
  {
    key: 'BAM',
    name: 'Convertible Mark',
  },
  {
    key: 'BBD',
    name: 'Barbados Dollar',
  },
  {
    key: 'BDT',
    name: 'Taka',
  },
  {
    key: 'BGN',
    name: 'Bulgarian Lev',
  },
  {
    key: 'BHD',
    name: 'Bahraini Dinar',
  },
  {
    key: 'BIF',
    name: 'Burundi Franc',
  },
  {
    key: 'BMD',
    name: 'Bermudian Dollar',
  },
  {
    key: 'BND',
    name: 'Brunei Dollar',
  },
  {
    key: 'BOB',
    name: 'Boliviano',
  },
  {
    key: 'BOV',
    name: 'Mvdol',
  },
  {
    key: 'BRL',
    name: 'Brazilian Real',
  },
  {
    key: 'BSD',
    name: 'Bahamian Dollar',
  },
  {
    key: 'BTN',
    name: 'Ngultrum',
  },
  {
    key: 'BWP',
    name: 'Pula',
  },
  {
    key: 'BYN',
    name: 'Belarusian Ruble',
  },
  {
    key: 'BZD',
    name: 'Belize Dollar',
  },
  {
    key: 'CAD',
    name: 'Canadian Dollar',
  },
  {
    key: 'CDF',
    name: 'Congolese Franc',
  },
  {
    key: 'CHE',
    name: 'WIR Euro',
  },
  {
    key: 'CHF',
    name: 'Swiss Franc',
  },
  {
    key: 'CHW',
    name: 'WIR Franc',
  },
  {
    key: 'CLF',
    name: 'Unidad de Fomento',
  },
  {
    key: 'CLP',
    name: 'Chilean Peso',
  },
  {
    key: 'CNY',
    name: 'Yuan Renminbi',
  },
  {
    key: 'COP',
    name: 'Colombian Peso',
  },
  {
    key: 'COU',
    name: 'Unidad de Valor Real',
  },
  {
    key: 'CRC',
    name: 'Costa Rican Colon',
  },
  {
    key: 'CUC',
    name: 'Peso Convertible',
  },
  {
    key: 'CUP',
    name: 'Cuban Peso',
  },
  {
    key: 'CVE',
    name: 'Cabo Verde Escudo',
  },
  {
    key: 'CZK',
    name: 'Czech Koruna',
  },
  {
    key: 'DJF',
    name: 'Djibouti Franc',
  },
  {
    key: 'DKK',
    name: 'Danish Krone',
  },
  {
    key: 'DOP',
    name: 'Dominican Peso',
  },
  {
    key: 'DZD',
    name: 'Algerian Dinar',
  },
  {
    key: 'EGP',
    name: 'Egyptian Pound',
  },
  {
    key: 'ERN',
    name: 'Nakfa',
  },
  {
    key: 'ETB',
    name: 'Ethiopian Birr',
  },
  {
    key: 'EUR',
    name: 'Euro',
  },
  {
    key: 'FJD',
    name: 'Fiji Dollar',
  },
  {
    key: 'FKP',
    name: 'Falkland Islands Pound',
  },
  {
    key: 'GBP',
    name: 'Pound Sterling',
  },
  {
    key: 'GEL',
    name: 'Lari',
  },
  {
    key: 'GHS',
    name: 'Ghana Cedi',
  },
  {
    key: 'GIP',
    name: 'Gibraltar Pound',
  },
  {
    key: 'GMD',
    name: 'Dalasi',
  },
  {
    key: 'GNF',
    name: 'Guinean Franc',
  },
  {
    key: 'GTQ',
    name: 'Quetzal',
  },
  {
    key: 'GYD',
    name: 'Guyana Dollar',
  },
  {
    key: 'HKD',
    name: 'Hong Kong Dollar',
  },
  {
    key: 'HNL',
    name: 'Lempira',
  },
  {
    key: 'HRK',
    name: 'Kuna',
  },
  {
    key: 'HTG',
    name: 'Gourde',
  },
  {
    key: 'HUF',
    name: 'Forint',
  },
  {
    key: 'IDR',
    name: 'Rupiah',
  },
  {
    key: 'ILS',
    name: 'New Israeli Sheqel',
  },
  {
    key: 'INR',
    name: 'Indian Rupee',
  },
  {
    key: 'IQD',
    name: 'Iraqi Dinar',
  },
  {
    key: 'IRR',
    name: 'Iranian Rial',
  },
  {
    key: 'ISK',
    name: 'Iceland Krona',
  },
  {
    key: 'JMD',
    name: 'Jamaican Dollar',
  },
  {
    key: 'JOD',
    name: 'Jordanian Dinar',
  },
  {
    key: 'JPY',
    name: 'Yen',
  },
  {
    key: 'KES',
    name: 'Kenyan Shilling',
  },
  {
    key: 'KGS',
    name: 'Som',
  },
  {
    key: 'KHR',
    name: 'Riel',
  },
  {
    key: 'KMF',
    name: 'Comorian Franc',
  },
  {
    key: 'KPW',
    name: 'North Korean Won',
  },
  {
    key: 'KRW',
    name: 'Won',
  },
  {
    key: 'KWD',
    name: 'Kuwaiti Dinar',
  },
  {
    key: 'KYD',
    name: 'Cayman Islands Dollar',
  },
  {
    key: 'KZT',
    name: 'Tenge',
  },
  {
    key: 'LAK',
    name: 'Lao Kip',
  },
  {
    key: 'LBP',
    name: 'Lebanese Pound',
  },
  {
    key: 'LKR',
    name: 'Sri Lanka Rupee',
  },
  {
    key: 'LRD',
    name: 'Liberian Dollar',
  },
  {
    key: 'LSL',
    name: 'Loti',
  },
  {
    key: 'LYD',
    name: 'Libyan Dinar',
  },
  {
    key: 'MAD',
    name: 'Moroccan Dirham',
  },
  {
    key: 'MDL',
    name: 'Moldovan Leu',
  },
  {
    key: 'MGA',
    name: 'Malagasy Ariary',
  },
  {
    key: 'MKD',
    name: 'Denar',
  },
  {
    key: 'MMK',
    name: 'Kyat',
  },
  {
    key: 'MNT',
    name: 'Tugrik',
  },
  {
    key: 'MOP',
    name: 'Pataca',
  },
  {
    key: 'MRU',
    name: 'Ouguiya',
  },
  {
    key: 'MUR',
    name: 'Mauritius Rupee',
  },
  {
    key: 'MVR',
    name: 'Rufiyaa',
  },
  {
    key: 'MWK',
    name: 'Malawi Kwacha',
  },
  {
    key: 'MXN',
    name: 'Mexican Peso',
  },
  {
    key: 'MXV',
    name: 'Mexican Unidad de Inversion (UDI)',
  },
  {
    key: 'MZN',
    name: 'Mozambique Metical',
  },
  {
    key: 'NAD',
    name: 'Namibia Dollar',
  },
  {
    key: 'NGN',
    name: 'Naira',
  },
  {
    key: 'NIO',
    name: 'Cordoba Oro',
  },
  {
    key: 'NOK',
    name: 'Norwegian Krone',
  },
  {
    key: 'NPR',
    name: 'Nepalese Rupee',
  },
  {
    key: 'NZD',
    name: 'New Zealand Dollar',
  },
  {
    key: 'OMR',
    name: 'Rial Omani',
  },
  {
    key: 'PAB',
    name: 'Balboa',
  },
  {
    key: 'PEN',
    name: 'Sol',
  },
  {
    key: 'PGK',
    name: 'Kina',
  },
  {
    key: 'PHP',
    name: 'Philippine Peso',
  },
  {
    key: 'PKR',
    name: 'Pakistan Rupee',
  },
  {
    key: 'PLN',
    name: 'Zloty',
  },
  {
    key: 'PYG',
    name: 'Guarani',
  },
  {
    key: 'QAR',
    name: 'Qatari Rial',
  },
  {
    key: 'RON',
    name: 'Romanian Leu',
  },
  {
    key: 'RSD',
    name: 'Serbian Dinar',
  },
  {
    key: 'RUB',
    name: 'Russian Ruble',
  },
  {
    key: 'RWF',
    name: 'Rwanda Franc',
  },
  {
    key: 'SAR',
    name: 'Saudi Riyal',
  },
  {
    key: 'SBD',
    name: 'Solomon Islands Dollar',
  },
  {
    key: 'SCR',
    name: 'Seychelles Rupee',
  },
  {
    key: 'SDG',
    name: 'Sudanese Pound',
  },
  {
    key: 'SEK',
    name: 'Swedish Krona',
  },
  {
    key: 'SGD',
    name: 'Singapore Dollar',
  },
  {
    key: 'SHP',
    name: 'Saint Helena Pound',
  },
  {
    key: 'SLL',
    name: 'Leone',
  },
  {
    key: 'SOS',
    name: 'Somali Shilling',
  },
  {
    key: 'SRD',
    name: 'Surinam Dollar',
  },
  {
    key: 'SSP',
    name: 'South Sudanese Pound',
  },
  {
    key: 'STN',
    name: 'Dobra',
  },
  {
    key: 'SVC',
    name: 'El Salvador Colon',
  },
  {
    key: 'SYP',
    name: 'Syrian Pound',
  },
  {
    key: 'SZL',
    name: 'Lilangeni',
  },
  {
    key: 'THB',
    name: 'Baht',
  },
  {
    key: 'TJS',
    name: 'Somoni',
  },
  {
    key: 'TMT',
    name: 'Turkmenistan New Manat',
  },
  {
    key: 'TND',
    name: 'Tunisian Dinar',
  },
  {
    key: 'TOP',
    name: 'Pa’anga',
  },
  {
    key: 'TRY',
    name: 'Turkish Lira',
  },
  {
    key: 'TTD',
    name: 'Trinidad and Tobago Dollar',
  },
  {
    key: 'TWD',
    name: 'New Taiwan Dollar',
  },
  {
    key: 'TZS',
    name: 'Tanzanian Shilling',
  },
  {
    key: 'UAH',
    name: 'Hryvnia',
  },
  {
    key: 'UGX',
    name: 'Uganda Shilling',
  },
  {
    key: 'USN',
    name: 'US Dollar (Next day)',
  },
  {
    key: 'UYI',
    name: 'Uruguay Peso en Unidades Indexadas (UI)',
  },
  {
    key: 'UYU',
    name: 'Peso Uruguayo',
  },
  {
    key: 'UYW',
    name: 'Unidad Previsional',
  },
  {
    key: 'UZS',
    name: 'Uzbekistan Sum',
  },
  {
    key: 'VED',
    name: 'Bolívar Soberano',
  },
  {
    key: 'VES',
    name: 'Bolívar Soberano',
  },
  {
    key: 'VND',
    name: 'Dong',
  },
  {
    key: 'VUV',
    name: 'Vatu',
  },
  {
    key: 'WST',
    name: 'Tala',
  },
  {
    key: 'XAF',
    name: 'CFA Franc BEAC',
  },
  {
    key: 'XAG',
    name: 'Silver',
  },
  {
    key: 'XAU',
    name: 'Gold',
  },
  {
    key: 'XBA',
    name: 'Bond Markets Unit European Composite Unit (EURCO)',
  },
  {
    key: 'XBB',
    name: 'Bond Markets Unit European Monetary Unit (E.M.U.-6)',
  },
  {
    key: 'XBC',
    name: 'Bond Markets Unit European Unit of Account 9 (E.U.A.-9)',
  },
  {
    key: 'XBD',
    name: 'Bond Markets Unit European Unit of Account 17 (E.U.A.-17)',
  },
  {
    key: 'XCD',
    name: 'East Caribbean Dollar',
  },
  {
    key: 'XDR',
    name: 'SDR (Special Drawing Right)',
  },
  {
    key: 'XOF',
    name: 'CFA Franc BCEAO',
  },
  {
    key: 'XPD',
    name: 'Palladium',
  },
  {
    key: 'XPF',
    name: 'CFP Franc',
  },
  {
    key: 'XPT',
    name: 'Platinum',
  },
  {
    key: 'XSU',
    name: 'Sucre',
  },
  {
    key: 'XTS',
    name: 'keys specifically reserved for testing purposes',
  },
  {
    key: 'XUA',
    name: 'ADB Unit of Account',
  },
  {
    key: 'XXX',
    name: 'The keys assigned for transactions where no name is involved',
  },
  {
    key: 'YER',
    name: 'Yemeni Rial',
  },
  {
    key: 'ZAR',
    name: 'Rand',
  },
  {
    key: 'ZMW',
    name: 'Zambian Kwacha',
  },
  {
    key: 'ZWL',
    name: 'Zimbabwe Dollar',
  },
];

export const identificationType = [
  { key: 'NRIC', name: 'NRIC' },
  { key: 'BRN', name: 'BRN' },
  { key: 'PASSPORT', name: 'PASSPORT' },
  { key: 'ARMY', name: 'ARMY' },
];
export const transactionType = [
  { key: 'REGULAR', name: 'Regular' },
  { key: 'BILLTOSHIP', name: 'Bill To - Ship To' },
];
export const frequencyBilling = [
  { key: 'DAILY', name: 'Daily' },
  { key: 'WEEK', name: 'Weekly' },
  { key: 'MONTH', name: 'Monthly' },
  { key: 'BIMONTH', name: 'Bimonthly' },
  { key: 'QUATER', name: 'Quarterly' },
  { key: 'BIANNUAL', name: 'Bi-annually' },
  { key: 'ANNUAL', name: 'Annually' },
];
export const taxRate = [
  { key: 'TAX5', name: 'Sales Tax 5' },
  { key: 'TAX10', name: 'Sales Tax 10' },
  { key: 'TAX6', name: 'Service Tax 6' },
  { key: 'TOURISM', name: 'Tourism' },
];

interface Classification {
  label: string;
  value: string;
  checked?: boolean;
}
export const classificationCode: Classification[] = [
  {
    value: '001',
    label: 'Breastfeeding equipment ',
    checked: false,
  },
  {
    value: '002',
    label: 'Child care centres and kindergartens fees ',
    checked: false,
  },
  {
    value: '003',
    label: 'Computer, smartphone or tablet ',
    checked: false,
  },
  {
    value: '004',
    label: 'Consolidated e-Invoice ',
    checked: false,
  },
  {
    value: '005',
    label:
      'Construction materials (as specified under Fourth Schedule of the Lembaga Pembangunan Industri Pembinaan Malaysia Act 1994) ',
    checked: false,
  },
  {
    value: '006',
    label: 'Disbursement ',
    checked: false,
  },
  {
    value: '007',
    label: 'Donation ',
    checked: false,
  },
  {
    value: '008',
    label: 'e-Commerce - e-Invoice to buyer / purchaser ',
    checked: false,
  },
  {
    value: '009',
    label: 'e-Commerce - Self-billed e-Invoice to seller, logistics, etc. ',
    checked: false,
  },
  {
    value: '010',
    label: 'Education fees ',
    checked: false,
  },
  {
    value: '011',
    label: 'Goods on consignment (Consignor) ',
    checked: false,
  },
  {
    value: '012',
    label: 'Goods on consignment (Consignee) ',
    checked: false,
  },
  {
    value: '013',
    label: 'Gym membership ',
    checked: false,
  },
  {
    value: '014',
    label: 'Insurance - Education and medical benefits ',
    checked: false,
  },
  {
    value: '015',
    label: 'Insurance - Takaful or life insurance ',
    checked: false,
  },
  {
    value: '016',
    label: 'Interest and financing expenses ',
    checked: false,
  },
  {
    value: '017',
    label: 'Internet subscription ',
    checked: false,
  },
  {
    value: '018',
    label: 'Land and building ',
    checked: false,
  },
  {
    value: '019',
    label:
      'Medical examination for learning disabilities and early intervention or rehabilitation treatments of learning disabilities ',
    checked: false,
  },
  {
    value: '020',
    label: 'Medical examination or vaccination expenses ',
    checked: false,
  },
  {
    value: '021',
    label: 'Medical expenses for serious diseases ',
    checked: false,
  },
  {
    value: '022',
    label: 'Others ',
    checked: false,
  },
  {
    value: '023',
    label: 'Petroleum operations (as defined in Petroleum (Income Tax) Act 1967) ',
    checked: false,
  },
  {
    value: '024',
    label: 'Private retirement scheme or deferred annuity scheme ',
    checked: false,
  },
  {
    value: '025',
    label: 'Motor vehicle ',
    checked: false,
  },
  {
    value: '026',
    label:
      'Subscription of books / journals / magazines / newspapers / other similar publications ',
    checked: false,
  },
  {
    value: '027',
    label: 'Reimbursement ',
    checked: false,
  },
  {
    value: '028',
    label: 'Rental of motor vehicle ',
    checked: false,
  },
  {
    value: '029',
    label: 'EV charging facilities (Installation, rental, sale / purchase or subscription fees) ',
    checked: false,
  },
  {
    value: '030',
    label: 'Repair and maintenance ',
    checked: false,
  },
  {
    value: '031',
    label: 'Research and development ',
    checked: false,
  },
  {
    value: '032',
    label: 'Foreign income ',
    checked: false,
  },
  {
    value: '033',
    label: 'Self-billed - Betting and gaming ',
    checked: false,
  },
  {
    value: '034',
    label: 'Self-billed - Importation of goods ',
    checked: false,
  },
  {
    value: '035',
    label: 'Self-billed - Importation of services ',
    checked: false,
  },
  {
    value: '036',
    label: 'Self-billed - Others ',
    checked: false,
  },
  {
    value: '037',
    label: 'Self-billed - Monetary payment to agents, dealers or distributors ',
    checked: false,
  },
  {
    value: '038',
    label:
      'Sports equipment, rental / entry fees for sports facilities, registration in sports competition or sports training fees imposed by associations / sports clubs / companies registered with the Sports Commissioner or Companies Commission of Malaysia and carrying out sports activities as listed under the Sports Development Act 1997 ',
    checked: false,
  },
  {
    value: '039',
    label: 'Supporting equipment for disabled person ',
    checked: false,
  },
  {
    value: '040',
    label: 'Voluntary contribution to approved provident fund ',
    checked: false,
  },
  {
    value: '041',
    label: 'Dental examination or treatment ',
    checked: false,
  },
  {
    value: '042',
    label: 'Fertility treatment ',
    checked: false,
  },
  {
    value: '043',
    label: 'Treatment and home care nursing, daycare centres and residential care centers ',
    checked: false,
  },
  {
    value: '044',
    label: 'Vouchers, gift cards, loyalty points, etc ',
    checked: false,
  },
  {
    value: '045',
    label: 'Self-billed - Non-monetary payment to agents, dealers or distributors',
    checked: false,
  },
];

export const classificationMap = classificationCode.reduce(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (acc: any, item) => {
    acc[item.value] = item.label;
    return acc;
  },
  {},
);

export const paymentMode = [
  {
    key: '01',
    name: 'Cash ',
  },
  {
    key: '02',
    name: 'Cheque ',
  },
  {
    key: '03',
    name: 'Bank Transfer ',
  },
  {
    key: '04',
    name: 'Credit Card ',
  },
  {
    key: '05',
    name: 'Debit Card ',
  },
  {
    key: '06',
    name: 'e-Wallet / Digital Wallet ',
  },
  {
    key: '07',
    name: 'Digital Bank ',
  },
  {
    key: '08',
    name: 'Others ',
  },
];

export const taxType = [
  {
    key: '01',
    name: 'Sales Tax',
  },
  {
    key: '02',
    name: 'Service Tax',
  },
  {
    key: '03',
    name: 'Tourism Tax',
  },
  {
    key: '04',
    name: 'High-Value Goods Tax',
  },
  {
    key: '05',
    name: 'Sales Tax on Low Value Goods',
  },
  {
    key: '06',
    name: 'Not Applicable',
  },
  {
    key: 'E',
    name: 'Tax Exemption',
  },
];

export const AdditionalFilters = [
  { value: 'docNumber', label: 'Document No.' },
  { value: 'totalInvoiceIncludingTaxAmount', label: 'Document Value' },
  { value: 'counterParty', label: 'Counterparty Name' },
  { value: 'tin', label: 'Counterparty TIN' },
  { value: 'uuid', label: 'UUID' },
  { value: 'submissionUID', label: 'Submission ID' },
  { value: 'pob', label: 'Place of Business Code as added while onboarding' },
  { value: 'buyerCode', label: 'Internal Customer Code' },
  { value: 'vendorCode', label: 'Internal Vendor Code' },
  { value: 'areaCode', label: 'Area Code' },
  { value: 'plantCode', label: 'Plant Code' },
  { value: 'departmentCode', label: 'Department Code' },
  { value: 'divisionCode', label: 'Division Code' },
  { value: 'branchCode', label: 'Branch Code' },
  { value: 'projectReferenceCode', label: 'Project Reference Code' },
  { value: 'costCenter', label: 'Cost Center' },
  { value: 'profitCenter', label: 'Profit Center' },
  { value: 'glCode', label: 'GL Code' },
  { value: 'uploadedId', label: ' Upload ID' },
];
